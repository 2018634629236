/* eslint-disable jsx-a11y/anchor-has-content */
import React, { memo, useEffect, useState } from 'react';
import { RegionCode } from '@kaa/api/idp';

import { i18nKeys } from '@kaa/i18n/idp/keys';
import { Icon, SwAlert, SwColumn } from '@kaa/ui-flanders/components';
import { Trans, useTranslation } from 'react-i18next';
import { OAuthLoginError } from '@kaa/api/idp/model/OAuthLoginError';
import { REGION_CONTACT_US_URL } from '../../idp/constants';

interface Props {
  regionCode: RegionCode;
}

export const OAuthErrorAlert = memo(({ regionCode }: Props) => {
  const [error, setError] = useState<OAuthLoginError | null>(null);
  const { t } = useTranslation();

  // Check if oauth error was set from signin-callback.html after login redirect
  useEffect(() => {
    const oauthError = sessionStorage.getItem('oauth_error');
    if (oauthError) {
      setError(oauthError as OAuthLoginError);
    }
  }, []);

  // Remove the error from sessionStorage only after it's displayed
  useEffect(() => {
    if (error) {
      sessionStorage.removeItem('oauth_error');
    }
  }, [error]);

  if (!error) return null;

  return (
    <SwColumn>
      <SwAlert
        icon={Icon.CLOSE}
        title={t(i18nKeys.error.loginErrorTitle)}
        modError
        style={{ maxWidth: '75%' }}
      >
        <Trans
          i18nKey={getOAuthErrorTranslationKey(error)}
          components={[
            <a
              key="link"
              href={t(REGION_CONTACT_US_URL[regionCode])}
              target="_blank"
              rel="noopener noreferrer"
            />,
          ]}
        />
      </SwAlert>
    </SwColumn>
  );
});

const getOAuthErrorTranslationKey = (oauthError: string): string => {
  switch (oauthError) {
    case OAuthLoginError.UserNotBelgian:
      return i18nKeys.error.loginErrorUserNotBelgian;
    case OAuthLoginError.UserNotFound:
      return i18nKeys.error.loginErrorUserNotFound;
    case OAuthLoginError.UserBlocked:
      return i18nKeys.error.loginErrorAccountBlocked;
    default:
      return i18nKeys.error.loginUnknownError;
  }
};
